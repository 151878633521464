<template>
  <div class="addBox">
    <!--  面包屑  -->
    <div class="mrj-layout-tabs-custom flex align-item-center" style="z-index:1;">
      <div @click="changeRoute" class="mrj-layout-tabs-custom-index flex align-item-center cursor-pointer">
        <div type="left" class="leftarrow" :class="imgflag?'leftarrow_hover':''" @mouseenter="imgflag=true" @mouseleave="imgflag=false">
          <i class="meiye-icon meiye-fanghui"></i>
        </div>
        <span @mouseenter="imgflag=true" @mouseleave="imgflag=false">{{ $route.meta.title }}</span>
      </div>
      <span class="mrj-layout-tabs-custom-fenge">/</span>
      <span class="mrj-layout-tabs-custom-inner-page">{{$route.query.type == 1?'新建合伙人方案':$route.query.type == 2?"编辑":"查看" }}</span>
    </div>
    <!-- 内容区 -->
    <div class="add">
      <div class="addleft">
        <div class="leftbox">
          <div class="onetxt" @click="goStep(1)" :class="step===1?'active':''">1</div>
          <div class="base" @click="goStep(1)" :class="step===1?'active1':''">基本配置</div>
          <div class="line"></div>
          <div class="onetxt" @click="goStep(2)" :class="step===2?'active':''">2</div>
          <div class="base" @click="goStep(2)" :class="step===2?'active1':''">收益配置</div>
          <div class="line"></div>
          <div class="onetxt" @click="goStep(3)" :class="step===3?'active':''">3</div>
          <div class="base" @click="goStep(3)" :class="step===3?'active1':''">权益配置</div>
          <div class="line"></div>
          <div class="onetxt" @click="goStep(4)" :class="step===4?'active':''">4</div>
          <div class="base" @click="goStep(4)" :class="step===4?'active1':''">使用配置</div>
        </div>
      </div>
      <div class="addright">
        <!-- 第一步 -->
        <div class="one" v-if="step===1">
          <div class="zengpinstep">
            <h2 class="twoh2">
              <span class="tpointer"></span>
              <span class="ttxt">基本配置</span>
            </h2>
            <a-row>
              <a-col :span="4">
                <div class="left-title">合伙人方案ID</div>
              </a-col>
              <a-col :span="16">
                <span class="right-title">{{form.scheme_identifier}}</span>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="4">
                <div class="left-title">方案名称<span class="xing" v-if="$route.query.type != 3">* </span></div>
              </a-col>
              <a-col :span="16" class="ant-input_1">
                <span class="right-title" v-if="$route.query.type == 3">{{form.scheme_name}}</span>
                <a-input v-else class="ant-input right-title" allowClear v-model="form.scheme_name" style="width:488px;" :maxLength="30" placeholder="输入名称，30字以内" />
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="4">
                <div class="left-title">合伙人申请入口开放时间<span class="xing" v-if="$route.query.type != 3">* </span>
                  <a-tooltip>
                    <template slot="title">
                      合伙人开始缴纳认购金,开放时间内门店可对合伙人进行录入。
                    </template>
                    <a-icon :style="{ color: '#819190' }" type="info-circle" />
                  </a-tooltip>
                </div>
              </a-col>
              <a-col :span="16">
                <a-range-picker @openChange="isactive1 = !isactive1" class="data_picker right-title" v-if="form.status_text=='待设置'" format="YYYY-MM-DD" :disabled-date="disabledDate" v-model="application_timeData" @change="onChangeApplication">
                  <i slot="suffixIcon" class="meiye-icon meiye-rili-moren" :class="{ 't-c-g': isactive1 }" style="font-size:12px;color: #CBD6DF;background:#F8FAFA;    margin-top: -5px;"></i>
                  <!-- <span slot="suffixIcon" class="meiye-icon meiye-routeicon-calendar"></span> -->
                </a-range-picker>
                <span class="right-title" v-else-if="$route.query.type != 1">{{form.application_start_date}}~{{form.application_end_date}}</span>
                <a-range-picker @openChange="isactive1 = !isactive1" v-else class="data_picker right-title" format="YYYY-MM-DD" :disabled-date="disabledDate" v-model="application_timeData" @change="onChangeApplication"> <i slot="suffixIcon" class="meiye-icon meiye-rili-moren" :class="{ 't-c-g': isactive1 }" style="font-size:12px;color: #CBD6DF;background:#F8FAFA;    margin-top: -5px;"></i></a-range-picker>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="4">
                <div class="left-title">合伙人身份结束时间<span class="xing" v-if="$route.query.type != 3">* </span>
                  <a-tooltip>
                    <template slot="title">
                      合伙人身份结束后,不再享受: <br>
                      1、转介绍客户消费所产生的佣金收益;<br>
                      2、合伙人权益。
                    </template>
                    <a-icon :style="{ color: '#819190' }" type="info-circle" />
                  </a-tooltip>
                </div>
              </a-col>
              <a-col :span="16">
                <a-date-picker @openChange="isactive = !isactive" :default-picker-value="moment(form.application_end_date, 'YYYY-MM-DD')" v-if="form.status_text=='待设置'" :disabled-date="disabledDateTwo" v-model="form.identify_end_date" @change="onChangeIdentify" class="data_picker right-title">
                  <i slot="suffixIcon" class="meiye-icon meiye-rili-moren" :class="{ 't-c-g': isactive }" style="font-size:12px;color: #CBD6DF;background:#F8FAFA;    margin-top: -5px;"></i>
                </a-date-picker>
                <span class="right-title" v-else-if="$route.query.type != 1">{{form.identify_end_date}}</span>
                <a-date-picker @openChange="isactive = !isactive" :default-picker-value="moment(form.application_end_date || '', 'YYYY-MM-DD')" v-else format="YYYY-MM-DD" :disabled-date="disabledDateTwo" v-model="form.identify_end_date" @change="onChangeIdentify" class="data_picke right-titler">
                  <i slot="suffixIcon" class="meiye-icon meiye-rili-moren" :class="{ 't-c-g': isactive }" style="font-size:12px;color: #CBD6DF;background:#F8FAFA;    margin-top: -5px;"></i>
                </a-date-picker>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="4">
                <div class="left-title">分红统计年度<span class="xing" v-if="$route.query.type != 3">* </span>
                  <a-tooltip placement="right" overlayClassName="spec_tooltip">
                    <template slot="title">
                      设置分红统计时间段:<br>
                      1、总部可生成授权门店所属该时间段的门店分红订单;<br>
                      2、门店可生成所属该时间段的合伙人分红订单, 在结算周期内可对合伙人分红订单进行结算。
                    </template>
                    <a-icon :style="{ color: '#819190' }" type="info-circle" />
                  </a-tooltip>
                </div>
              </a-col>
              <a-col :span="16">
                <a-date-picker mode="year" format="YYYY" v-if="form.status_text=='待设置'" v-model="form.dividend_ratio_year" :open="yearPickShow" @openChange="handleOpenChange" @panelChange="onChangeYear" class="data_picker right-title">
                  <i slot="suffixIcon" class="meiye-icon meiye-rili-moren" :class="{ 't-c-g': isactive2 }" style="font-size:12px;color: #CBD6DF;background:#F8FAFA;    margin-top: -5px;"></i>
                </a-date-picker>
                <span class="right-title" v-else-if="$route.query.type != 1">{{form.dividend_ratio_year || '--'}} 年</span>
                <a-date-picker mode="year" format="YYYY" v-else v-model="form.dividend_ratio_year" :open="yearPickShow" @openChange="handleOpenChange" @panelChange="onChangeYear" class="data_picker right-title">
                  <i slot="suffixIcon" class="meiye-icon meiye-rili-moren" :class="{ 't-c-g': isactive2 }" style="font-size:12px;color: #CBD6DF;background:#F8FAFA;    margin-top: -5px;"></i>
                </a-date-picker>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="4"></a-col>
              <a-col :span="16">
                <a-tooltip placement="bottomLeft" overlayClassName="spec_tooltip">
                  <template slot="title">
                    <div>方案保存后，再次编辑时无法修改方案「基本配置」页面中:合伙人申请入口开放时间、
                      合伙人身份结束时间、分红统计年度内容,编辑内容只针对编辑时新添加的授权门店有效</div>
                  </template>
                  <a-button type="primary" @click="onStep(2)">
                    <a-icon type="info-circle" />
                    下一步</a-button>
                </a-tooltip>
                <a-button class="mgl-20" style="margin-left:20px" @click="cancel()">取消</a-button>
              </a-col>
            </a-row>

            <!-- <a-form-model :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }" labelAlign="left">
              <a-form-model-item label="合伙人方案ID">
                {{form.scheme_identifier}}
              </a-form-model-item>
              <a-form-model-item label="方案名称" required>
                <span v-if="$route.query.type == 3">{{form.scheme_name}}</span>
                <a-input v-else v-model="form.scheme_name" style="width:60%" :maxLength="30" placeholder="输入名称，30字以内" />
              </a-form-model-item>
              <a-form-model-item label="合伙人申请入口开放时间" required>
                <span v-if="$route.query.type == 3">{{form.application_start_date}}~{{form.application_end_date}}</span>
                <a-range-picker v-else format="YYYY-MM-DD" :disabled-date="disabledDate" v-model="application_timeData" @change="onChangeApplication" />
              </a-form-model-item>
              <a-form-model-item label="合伙人身份结算时间" required>
                <span v-if="$route.query.type == 3">{{form.identify_end_date}}</span>
                <a-date-picker v-else format="YYYY-MM-DD" :disabled-date="disabledDate" v-model="form.identify_end_date" @change="onChangeIdentify" />
              </a-form-model-item>
              <a-form-model-item label="分红统计年度" required>
                <span v-if="$route.query.type == 3">{{form.dividend_ratio_year || '--'}}</span>
                <a-date-picker mode="year" format="YYYY" v-else v-model="form.dividend_ratio_year" :open="yearPickShow" @openChange="handleOpenChange" @panelChange="onChangeYear" />
              </a-form-model-item>
              <a-form-model-item label="" style="margin-left:20.83333333%">
                <a-tooltip placement="top">
                  <template slot="title">
                    <div>方案保存后，再次编辑时无法修改方案「基本配置」页面中:合伙人申请入口开放时间、
                      合伙人身份结束时间、分红统计年度内容,编辑内容只针对编辑时新添加的授权门店有效</div>
                  </template>
                  <a-button type="primary" @click="onStep(2)">
                    <a-icon :style="{ color: '#819190' }" type="info-circle" />
                    下一步</a-button>
                </a-tooltip>
                <a-button class="mgl-20" @click="cancel()">取消</a-button>
              </a-form-model-item>
            </a-form-model> -->
          </div>
        </div>
        <!-- 第二步 -->
        <div class="two" v-if="step===2">
          <div class="zengpinstep">
            <h2 class="twoh2">
              <span class="tpointer"></span>
              <span class="ttxt">收益配置</span>
            </h2>
            <div class="mgl-12">
              <!-- 认购金 -->
              <h2 class="twoh1">
                <span class="tpointer"></span>
                <span class="ttxt">认购金<span class="ttxtspan">成为合伙人需要购买的门店消费股</span></span>
              </h2>
              <a-row>
                <a-col :span="5">
                  <div class="textColor1">消费股价格<span class="xing">*</span></div>
                  <span class="font-22 " v-if="$route.query.type == 3">{{form.share_price}}</span>
                  <a-input-number class=" ant-input_1" style="width: 178px;" size="large" v-else v-model="form.share_price" id="inputNumber" :min="0" /> <span style="font-size: 14px;font-weight: 400;color: #A4B3B1;"> 元 / 股</span>
                </a-col>
                <a-col :span="5">
                  <div class="textColor1">起购股数<span class="xing">*</span></div>
                  <span class="font-22" v-if="$route.query.type == 3">{{form.min_shares}}</span>
                  <a-input-number class=" ant-input_1" style="width: 178px;" size="large" v-else v-model="form.min_shares" id="inputNumber" :min="0" /> <span style="font-size: 14px;font-weight: 400;color: #A4B3B1;">股</span>
                </a-col>
                <a-col :span="5">
                  <div class="textColor1">溢价比例<span class="xing">*</span></div>
                  <span class="font-22" v-if="$route.query.type == 3">{{form.premium_rate}}</span>
                  <a-input-number class=" ant-input_1" style="width: 178px;" size="large" v-else v-model="form.premium_rate" id="inputNumber" :min="0" :max="100" /> <span style="font-size: 14px;font-weight: 400;color: #A4B3B1;">%</span>
                </a-col>
              </a-row>
              <!-- <p class="textSmall"><a-icon type="info-circle" theme="filled" :style="{ color: '#98B9B7' }" /> 最低认购价 30000元 产生认购金35000元 </p> -->
              <!-- 营业分红 -->
              <h2 class="twoh1 mgt-40">
                <span class="tpointer"></span>
                <span class="ttxt">营业分红<span class="ttxtspan">合伙人期间享受门店营业额业绩效益的年度分红
                    <a-tooltip placement="topLeft" overlayClassName="spec_tooltip">
                      <template slot="title">
                        基础业绩(收现):卡项（ 包括服务、 项目、 套盒、 权益卡） 产品的收款总额（ 排除合作项目， 收款方式不含卡扣）<br>
                        合作项目(收现):合作项目的收款总额（收款方式不含卡扣）<br>
                        产品业绩(收现):实物产品的收款总额（排除合作项目，收款方式不含卡扣）<br>
                      </template>
                      <a-icon :style="{ color: '#819190' }" type="info-circle" />
                    </a-tooltip>
                  </span></span>
              </h2>
              <a-table class="mgb-12 mgt-10 detailsTable" style="width:99.99%" :pagination="false" row-key="id" size="middle" :loading="loading" :columns="$route.query.type == 3?config.add.columns_1:config.add.columns_1_c" :data-source="form.dividend_ratio_list">
                <template slot="_0" slot-scope="text, record, index">
                  <a-select placeholder="选择类型" v-if="record.editable" :size="size" :ref="'SpecValueRef_' + index" v-model="record.dividend_business" style="width: 180px">
                    <a-select-option v-for="item in specList" :key="item.id" :disabled="isCheak(form.dividend_ratio_list,item.id)">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                  <span v-else-if="specList.filter(it=>record.dividend_business==it.id)[0]">{{specList.filter(it=>record.dividend_business==it.id)[0].name}}</span>
                  <span v-else>{{record.dividend_business || '--'}}</span>
                </template>
                <template slot="_1" slot-scope="text, record, index">
                  <template>
                    <a-input-number class=" ant-input_1" style="width: 180px" v-if="record.editable" v-model="record.dividend_rate" :min="0" :max="100" />
                    <!-- <a-input v-model="record.dividend_rate"  type="number" style="width: 180px" v-if="record.editable" placeholder="输入百分比" suffix="%" /> -->
                    <span v-else>{{record.dividend_rate}}</span> %
                  </template>
                </template>
                <template slot="_3" slot-scope="text, record, index">
                  <div class="editable-row-operations" v-if="$route.query.type != 3">
                    <span v-if="record.editable" :key="record.id">
                      <a-button type="primary" class="small_primary" size="small" @click="saveItem(record, index, 'dividend_ratio_list')">保存
                      </a-button>
                      <a-button class="smallbtn" style="margin-right:0px !important" size="small" @click="cancelItem(index, 'dividend_ratio_list')">取消</a-button>
                    </span>
                    <span v-else :key="record.id">
                      <a-tooltip placement="bottom">
                        <template slot="title">
                          <span>编辑</span>
                        </template>
                        <span class="plus-icon plus-icon-two meiye-icon meiye-moren" @click="editItem(record, 'dividend_ratio_list')"></span>
                      </a-tooltip>
                      <a-tooltip placement="bottom">
                        <template slot="title">
                          <span>删除</span>
                        </template>
                        <a-popconfirm title="确定删除吗？" @confirm="() => deleteItem(record, index, 'dividend_ratio_list')">
                          <span class="mgl-12 plus-icon plus-icon-two meiye-icon meiye-routeicon-ashbin"></span>
                        </a-popconfirm>
                      </a-tooltip>
                    </span>
                  </div>
                </template>
                <template slot="footer" v-if="$route.query.type != 3 && form.dividend_ratio_list.length<3">
                  <div class="a-center  cursor-pointer" @click="addTableItem('dividend_ratio_list')">
                    <!-- <a-icon class="theme-color plus-icon" type="plus" /> -->
                    <span class="meiye-routeicon-add-copy meiye-icon plus-icon plus-icon-two theme-color" style="line-height:22px"></span>
                    <span class="mgl-8 theme-color cursor-pointer">新增方式</span>
                  </div>
                </template>
              </a-table>
              <h2 class="twoh1 mgt-40">
                <span class="tpointer"></span>
                <span class="ttxt">结算合伙人分红周期</span>
              </h2>
              <span class="mgl-12" style="font-size: 14px;color: @fontColor1;" v-if="$route.query.type == 3">{{form.dividend_settlement_period==1?'按季度结算':'按年结算'}}</span>
              <a-radio-group v-else v-model="form.dividend_settlement_period" :disabled="$route.query.type == 3" class="lable_radiogroup_padding">
                <a-radio-button :value="1">
                  按季度结算
                </a-radio-button>
                <a-radio-button :value="2">
                  按年结算
                </a-radio-button>
              </a-radio-group>
              <!-- 佣金 -->
              <h2 class="twoh1 mgt-40" style="margin-bottom:8px">
                <span class="tpointer"></span>
                <span class="ttxt">佣金<span class="ttxtspan">合伙人获得转介绍客户消费订单佣金计算规则</span></span>
              </h2>
              <a-form-model :label-col="{ span: 2 }" :wrapper-col="{ span: 16 }" labelAlign="left">
                <a-form-model-item label="佣金订单来源">
                  <a-radio-group v-model="form.dividend_order_source" :disabled="$route.query.type == 3">
                    <a-radio :value="1">收银流水</a-radio>
                  </a-radio-group>
                </a-form-model-item>
              </a-form-model>
              <h2 class="twoh3">
                <span class="tpointer"></span>
                <span style="color: #A4B3B1;font-size:14px;margin-right:8px">产品类型 :</span><span class="ttxt">非合作项目
                  <a-tooltip placement="top">
                    <template slot="title">
                      非合作项目: 实物、服务、项目卡、套盒、权益卡(不包含储值卡)
                    </template>
                    <a-icon :style="{ color: '#819190' }" type="info-circle" />
                  </a-tooltip>
                </span>
              </h2>
              <a-table class="mgb-12 mgt-10 detailsTable" style="width:99.99%" :pagination="false" row-key="id" size="middle" :loading="loading1" :columns="$route.query.type == 3?config.add.columns_2:config.add.columns_2_c" :data-source="form.commission_rule_no">
                <template slot="_0" slot-scope="text, record, index">
                  <a-select placeholder="选择类型" v-if="record.editable" :size="size" :ref="'productTypeRef_' + index" v-model="record.payment_method" style="width: 180px">
                    <a-select-option v-for="item in paymentList" :value="item.id" :key="item.id" :disabled="isCheakNo(form.commission_rule_no,item.id)">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                  <span v-else-if="paymentList.filter(it=>record.payment_method==it.id)[0]">{{paymentList.filter(it=>record.payment_method==it.id)[0].name}}</span>
                  <span v-else>{{record.payment_method || '--'}}</span>
                </template>
                <template slot="_1" slot-scope="text, record, index">
                  <template>
                    <a-input-number class=" ant-input_1" v-model="record.first_purchase_rate" style="width: 180px" v-if="record.editable" :min="0" :max="100" />
                    <!-- <a-input v-model="record.first_purchase_rate" style="width: 180px" v-if="record.editable" placeholder="输入百分比" suffix="%" /> -->
                    <span v-else>{{record.first_purchase_rate}}</span> %
                  </template>
                </template>
                <template slot="_2" slot-scope="text, record, index">
                  <template>
                    <a-input-number class=" ant-input_1" v-model="record.repurchase_rate" style="width: 180px" v-if="record.editable" :min="0" :max="100" />
                    <!-- <a-input v-model="record.repurchase_rate" style="width: 180px" v-if="record.editable" placeholder="输入百分比" suffix="%" /> -->
                    <span v-else>{{record.repurchase_rate}}</span> %
                  </template>
                </template>
                <template slot="_3" slot-scope="text, record, index">
                  <div class="editable-row-operations" v-if="$route.query.type != 3">
                    <span v-if="record.editable" :key="record.id">
                      <a-button type="primary" class="small_primary" size="small" @click="saveItem(record, index, 'commission_rule_no')">保存
                      </a-button>
                      <a-button class="smallbtn" style="margin-right:0px !important" @click="cancelItem(index, 'commission_rule_no')" size="small">取消</a-button>
                    </span>
                    <span v-else :key="record.id">
                      <a-tooltip placement="bottom">
                        <template slot="title">
                          <span>编辑</span>
                        </template>
                        <span class="plus-icon plus-icon-two meiye-icon meiye-moren" @click="editItem(record, 'commission_rule_no')"></span>
                      </a-tooltip>
                      <a-tooltip placement="bottom">
                        <template slot="title">
                          <span>删除</span>
                        </template>
                        <a-popconfirm title="确定删除吗？" @confirm="() => deleteItem(record, index, 'commission_rule_no')">
                          <span class="mgl-12 plus-icon plus-icon-two meiye-icon meiye-routeicon-ashbin"></span>
                        </a-popconfirm>
                      </a-tooltip>
                    </span>
                  </div>
                </template>
                <template slot="footer" v-if="$route.query.type != 3">
                  <div class="a-center  cursor-pointer" @click="addTableItem('commission_rule_no',2)">
                    <span class="meiye-routeicon-add-copy meiye-icon plus-icon plus-icon-two theme-color" style="line-height:22px"></span>
                    <span class="mgl-8 theme-color cursor-pointer">新增方式</span>
                  </div>
                </template>
              </a-table>
              <h2 class="twoh3 mgt-30">
                <span class="tpointer"></span>
                <span style="color: #A4B3B1;font-size:14px;margin-right:8px">产品类型 :</span><span class="ttxt">合作项目
                  <a-tooltip placement="top">
                    <template slot="title">
                      合作项目:设置为合作项目的实物、服务、项目卡、套盒、权益卡(不包含储值卡)
                    </template>
                    <a-icon :style="{ color: '#819190' }" type="info-circle" />
                  </a-tooltip>
                </span>
              </h2>
              <a-table class="mgb-12 mgt-10 detailsTable" style="width:99.99%" :pagination="false" row-key="id" :loading="loading2" size="middle" :columns="$route.query.type == 3?config.add.columns_2:config.add.columns_2_c" :data-source="form.commission_rule_yes">
                <template slot="_0" slot-scope="text, record, index">
                  <a-select placeholder="选择类型" v-if="record.editable" :size="size" :ref="'productTypeRef_' + index" v-model="record.payment_method" style="width: 180px">
                    <a-select-option v-for="item in paymentListYes" :value="item.id" :key="item.id" :disabled="isCheakNo(form.commission_rule_yes,item.id)">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                  <span v-else-if="paymentListYes.filter(it=>record.payment_method==it.id)[0]">{{paymentListYes.filter(it=>record.payment_method==it.id)[0].name}}</span>
                  <span v-else>{{record.payment_method || '--'}} %</span>
                </template>
                <template slot="_1" slot-scope="text, record, index">
                  <template>
                    <a-input-number class=" ant-input_1" v-model="record.first_purchase_rate" style="width: 180px" v-if="record.editable" :min="0" :max="100" />
                    <!-- <a-input v-model="record.first_purchase_rate" style="width: 180px" v-if="record.editable" placeholder="输入百分比" suffix="%" /> -->
                    <span v-else>{{record.first_purchase_rate}}</span> %
                  </template>
                </template>
                <template slot="_2" slot-scope="text, record, index">
                  <template>
                    <a-input-number class=" ant-input_1" v-model="record.repurchase_rate" style="width: 180px" v-if="record.editable" :min="0" :max="100" />
                    <!-- <a-input v-model="record.repurchase_rate" style="width: 180px" v-if="record.editable" placeholder="输入百分比" suffix="%" /> -->
                    <span v-else>{{record.repurchase_rate}}</span> %
                  </template>
                </template>
                <template slot="_3" slot-scope="text, record, index">
                  <div class="editable-row-operations" v-if="$route.query.type != 3">
                    <span v-if="record.editable" :key="record.id">
                      <a-button type="primary" class="small_primary" size="small" @click="saveItem(record, index, 'commission_rule_yes')">保存
                      </a-button>
                      <a-button class="smallbtn" style="margin-right:0px !important" @click="cancelItem(index, 'commission_rule_yes')" size="small">取消</a-button>
                    </span>
                    <span v-else :key="record.id">
                      <a-tooltip placement="bottom">
                        <template slot="title">
                          <span>编辑</span>
                        </template>
                        <span class="plus-icon plus-icon-two meiye-icon meiye-moren" @click="editItem(record, 'commission_rule_yes')"></span>
                      </a-tooltip>
                      <a-tooltip placement="bottom">
                        <template slot="title">
                          <span>删除</span>
                        </template>
                        <a-popconfirm title="确定删除吗？" @confirm="() => deleteItem(record, index, 'commission_rule_yes')">
                          <span class="mgl-12 plus-icon plus-icon-two meiye-icon meiye-routeicon-ashbin"></span>
                        </a-popconfirm>
                      </a-tooltip>
                    </span>
                  </div>
                </template>
                <template slot="footer" v-if="$route.query.type != 3">
                  <div class="a-center  cursor-pointer" @click="addTableItem('commission_rule_yes',1)">
                    <span class="meiye-routeicon-add-copy meiye-icon plus-icon plus-icon-two theme-color" style="line-height:22px"></span>
                    <span class="mgl-8 theme-color cursor-pointer">新增方式</span>
                  </div>
                </template>
              </a-table>
            </div>
          </div>
          <div class="three-btns">
            <a-button type="primary" @click="onStep(3)" class="stepbtnnext1">下一步</a-button>
            <a-button class="stepbackbtn mgl-20" @click="step = 1">上一步</a-button>
            <a-button class="stepbackbtn mgl-20" @click="cancel()">取消</a-button>
          </div>
        </div>
        <!-- 第三步 -->
        <div class="three" v-if="step===3">
          <div class="zengpinstep">
            <h2 class="twoh2">
              <span class="tpointer"></span>
              <span class="ttxt">权益配置</span>
            </h2>
            <!-- <a-button v-if="$route.query.type != 3" type="primary" class="mgb-12" size="small" @click="editItemQy(1)"><a-icon type="plus" />添加权益</a-button> -->
            <a-table class="mgb-12 mgt-10 detailsTable" style="width:99.99%" :pagination="false" size="middle" :columns="config.add.columns_3" :data-source="form.benefit">
              <template slot="_1" slot-scope="text, record, index">
                <template v-if="record.image">
                  <img class="wh30Img" :src="record.image" @click="handlePreview(record,'image/*')">
                </template>
                <span v-else>暂未上传</span>
              </template>
              <template slot="_3" slot-scope="text, record, index">
                <div class="editable-row-operations">
                  <a-button class="smallbtn" @click="lookItemQy(record,index)">查看</a-button>
                  <a-button class="smallbtn" v-if="$route.query.type != 3" @click="editItemQy(2,record,index)">修改</a-button>
                  <a-button class="smallbtn" v-if="$route.query.type != 3" @click="deleteItemQy(record, index)">移除</a-button>
                </div>
              </template>
              <template slot="footer" v-if="$route.query.type != 3">
                <div class="a-center  cursor-pointer" @click="editItemQy(1)">
                  <span class="meiye-routeicon-add-copy meiye-icon plus-icon plus-icon-two theme-color" style="line-height:22px"></span>
                  <span class="mgl-8 theme-color">新增权益</span>
                </div>
              </template>
            </a-table>
            <div style="margin-top: 40px;">
              <a-button type="primary" @click="onStep(4)" class="stepbtnnext1">下一步</a-button>
              <a-button @click="step = 2" class="stepbackbtn mgl-20">上一步</a-button>
              <a-button class="stepbackbtn mgl-20" @click="cancel()">取消</a-button>
            </div>
          </div>
        </div>
        <!-- 第四步 -->
        <div class="four" v-if="step===4">
          <div class="zengpinstep" style="height: calc(100vh - 148px);overflow-y: auto;">
            <h2 class="twoh2">
              <span class="tpointer"></span>
              <span class="ttxt">使用配置</span>
            </h2>
            <a-form-model labelAlign="left">
              <div class="shop-title">
                门店授权<span class="right-title mgl-12">已选 {{$route.query.type == 3?form.shop_list.length:withBrandCheck.length}}家</span>
              </div>
              <!-- <div class="mgl-12" v-if="$route.query.type == 3">
                <span class="y-tag" v-if="form.shop_list.length" v-for="v in form.shop_list"> {{v.name}} </span>
              </div> -->
              <template>
                <div class="shop-body">
                  <div class="top flex" v-if="$route.query.type != 3">
                    <div class="left">
                      <a-button class="smallbtn" size="small" style="width: 52px;height:28px" @click="checkAll">全选</a-button>
                      <a-button class="smallbtn" size="small" style="width: 52px;height:28px" @click="invertSelection">反选</a-button>
                      <a-button class="normalbtn ant-btn-link" size="small" style="width: 52px;height:28px;position: relative;top: 1px;" @click="reset">重置</a-button>
                    </div>
                    <div class="right">
                      <a-input size="small" class="y-input" v-model="valueInput" placeholder="搜索门店">
                        <i slot="prefix" class="meiye-icon meiye-sousuo"></i>
                      </a-input>
                      <div class="flex y-center" style="margin-right:50px">
                        <div class="mgr-12" style="margin-bottom:2px">{{isAllCheck?'展示已选门店':'展示全部门店'}}</div> <a-switch v-model="isAllCheck" @change="checkgroup" />
                      </div>
                    </div>
                  </div>
                  <div class="top flex" v-else>
                    <a-input size="small" class="y-input" style="padding-top:9px" v-model="valueInput" placeholder="搜索门店">
                      <i slot="prefix" class="meiye-icon meiye-sousuo"></i>
                    </a-input>
                  </div>

                  <div class="conter flex" v-if="$route.query.type != 3">
                    <div class="left">
                      <div class="item-text" :class="{'item-text-corlor':inActive == it.value}" v-for="(it,i) in withBrand" :key="it.id" @click="checkboxgroup(it)">
                        <a-checkbox :checked="valueCheckboxAll.includes(it.value)" @change.stop="checkboxgroupCk($event,it)"></a-checkbox>
                        {{ it.province }}
                      </div>
                      <!-- <a-checkbox-group v-model="valueCheckbox" name="checkboxg" :options="withBrand" /> -->
                    </div>
                    <div class="right">
                      <a-checkbox-group v-model="withBrandCheck" v-if="!isAllCheck">
                        <a-checkbox v-for="v in withBrandList" :key="v.id" :value="v.id">
                          <a-tooltip placement="top">
                            <template slot="title">
                              {{ v.name }}
                            </template>
                            {{ v.name }}
                          </a-tooltip>
                        </a-checkbox>
                      </a-checkbox-group>
                      <a-checkbox-group v-model="withBrandCheck" v-else>
                        <a-checkbox v-for="v in withBrandList" :key="v.id" :value="v.id">
                          <a-tooltip placement="top">
                            <template slot="title">
                              {{ v.name }}
                            </template>
                            {{ v.name }}
                          </a-tooltip>
                        </a-checkbox>
                      </a-checkbox-group>
                    </div>
                  </div>
                  <!-- <div class="conter flex" v-else>
                    <div class="left" style="padding-top:6px">
                      <div class="item-text" v-if="form.shop_list.length" v-for="(it,i) in withBrand" :key="it.id" @click="checkboxgroup(it)">
                        {{ it.province }}
                      </div>
                      <div class="text-center" style="width:100%" v-if="form.shop_list.length == 0">
                        <a-list :data-source="[]" />
                      </div>
                    </div>
                    <div class="right3">
                      <div class="item-text" v-for="it in form.shop_list" :key="it.id">
                        {{ it.name }}
                      </div>
                    </div>
                  </div> -->
                </div>
              </template>
              <!-- <a-form-model-item label="授权">
                <span v-if="$route.query.type == 3">以门店为单位</span>
                <a-radio-group v-else v-model="form.authorized">
                  <a-radio :value="1">
                    以门店为单位
                  </a-radio>
                </a-radio-group>
              </a-form-model-item> -->
            </a-form-model>
            <div style="margin-top: 40px;margin-left:12px">
              <a-tooltip placement="topLeft" v-if="$route.query.type != 3" overlayClassName="spec_tooltip">
                <template slot="title">
                  <div>已添加的授权门店无法删除,如需删除请联系技术人员更改;已添加的授权门店所使用的方案内容无法修改</div>
                </template>
                <a-button type="primary" class="stepbtnnext1" @click="submit">
                  <a-icon type="info-circle" />
                  保存</a-button>
              </a-tooltip>
              <a-button class="stepbackbtn mgl-20" @click="step = 3">上一步</a-button>
              <a-button class="stepbackbtn mgl-20" @click="cancel()">取消</a-button>
            </div>
          </div>

        </div>
      </div>
      <a-modal :title="itemQyType==1?'添加权益':'修改权益'" :visible="modal.visible" @cancel="handleCancel" :width="800">
        <div class="row-body">
          <div class="top">
            <div :class="qyStep==1?'yuan-blue':'yuan'">1</div>
            <div class="text-title">基础信息</div>
            <div class="dian">·······</div>
            <div :class="qyStep==2?'yuan-blue':'yuan'">2</div>
            <div class="text-title">权益详情</div>
          </div>
        </div>
        <div class="mgt-20" v-if="qyStep==1">
          <a-form-model :label-col="{ span: 3 }" :wrapper-col="{ span: 16 }" labelAlign="left">
            <a-form-model-item label="权益图" style="margin-bottom:13px">
              <a-upload name="file" list-type="picture-card" class="avatar-uploader" :show-upload-list="false" :action="baseUrl+'/api/v2/tools/upload'" :before-upload="beforeUpload" @change="handleChange">
                <img v-if="modal.form.image" :src="modal.form.image" alt="avatar" />
                <div v-else>
                  <a-icon :type="modal.url_loading ? 'loading' : 'plus'" />
                  <div class="ant-upload-text">上传</div>
                </div>
              </a-upload>
            </a-form-model-item>
            <a-form-model-item label="权益名称" required>
              <a-input v-model="modal.form.name" :maxLength="20" class="ant-input_1" placeholder="请输入权益名称，限20字以内" />
            </a-form-model-item>
            <a-form-model-item label="权益次数" required>
              <a-radio-group class="radio_nomal1" v-model="modal.form.count_type">
                <a-radio :value="1">不限量</a-radio>
                <a-radio :value="2">限量</a-radio>
              </a-radio-group>
              <a-input-number class=" ant-input_1 mgr-12" v-if="modal.form.count_type == 2" v-model="modal.form.count" id="countNumber" :min="1" />
              <a-tooltip placement="right" v-if="modal.form.count_type == 2">
                <template slot="title">
                  <div>限量值默认为1，不可小于1</div>
                </template>
                <a-icon :style="{ color: '#819190' }" type="info-circle" />
              </a-tooltip>
            </a-form-model-item>
            <a-form-model-item label="权益规格">
              <a-input class="ant-input_1" v-model="modal.form.specification" :maxLength="10" placeholder="请输入规格名称，10字内" />
            </a-form-model-item>
            <a-form-model-item label="权益单位">
              <a-input class="ant-input_1 " v-model="modal.form.unit" style="width: 213px" placeholder="请请输入单位，可为空" />
            </a-form-model-item>
          </a-form-model>
        </div>
        <div class="mgt-20" v-if="qyStep==2">
          <shop-editor class="goods_editor" @transfer="getUser" v-model="modal.form.desc">
          </shop-editor>
        </div>
        <template slot="footer">
          <a-button @click="handleCancel">取消</a-button>
          <a-button v-if="qyStep==1" @click="qyStep=2" type="primary">下一页</a-button>
          <a-button v-if="qyStep==2" @click="qyStep=1">上一页</a-button>
          <a-button v-if="qyStep==2" @click="handleOk" type="primary">保存</a-button>
        </template>
      </a-modal>
      <a-modal title="查看权益" :visible="modal_look.visible" @cancel="modal_look.visible=false" :width="800">
        <div class="row-body">
          <div class="top">
            <div :class="qyStep==1?'yuan-blue':'yuan'">1</div>
            <div class="text-title">基础信息</div>
            <div class="dian">·······</div>
            <div :class="qyStep==2?'yuan-blue':'yuan'">2</div>
            <div class="text-title">权益详情</div>
          </div>
        </div>
        <div class="mgt-20" v-if="qyStep==1">
          <a-form-model :label-col="{ span: 3 }" :wrapper-col="{ span: 16 }" labelAlign="left">
            <a-form-model-item label="权益图">
              <a-avatar shape="square" :size="64" v-if="modal_look.form.image" :src="modal_look.form.image" icon="user" />
              <span v-else>--</span>
            </a-form-model-item>
            <a-form-model-item label="权益名称">
              <span>{{modal_look.form.name || '--'}}</span>
            </a-form-model-item>
            <a-form-model-item label="权益次数">
              <span>{{modal_look.form.count_type==1?'不限量':'限量'}}</span>
              <span v-if="modal_look.form.count_type == 2"> {{modal_look.form.count || '--'}} 次 </span>
            </a-form-model-item>
            <a-form-model-item label="权益规格">
              <span>{{modal_look.form.specification || '--'}}</span>
            </a-form-model-item>
            <a-form-model-item label="权益单位">
              <span>{{modal_look.form.unit|| '--'}}</span>
            </a-form-model-item>
          </a-form-model>
        </div>
        <div class="mgt-20" v-if="qyStep==2">
          <a-form-model :label-col="{ span: 3 }" :wrapper-col="{ span: 20 }" labelAlign="left">
            <a-form-model-item label="权益详情">
              <!-- <div v-html="modal_look.form.desc">{{modal_look.form.desc}}</div> -->
              <shop-editor class="goods_editor" @transfer="getUser" v-model="modal_look.form.desc">
              </shop-editor>
            </a-form-model-item>
          </a-form-model>
        </div>
        <template slot="footer">
          <a-button @click="modal_look.visible=false">取消</a-button>
          <a-button v-if="qyStep==1" @click="qyStep=2" type="primary">下一页</a-button>
          <a-button v-if="qyStep==2" @click="qyStep=1">上一页</a-button>
        </template>
      </a-modal>
    </div>
  </div>

</template>
<script>
import moment from 'moment';
import config from './config';
import { partnerGenerated, partnerAdd, partnerSave, partnerDetail, InstitutionProvince, partnerPayTypeList } from '@/api/partner'
import UploadImage from './modules/drag'
import ShopEditor from './modules/wangeditor'
import Msg from '@/components/mrj-menu/msg'
import storage from 'store'
import { find, push } from 'store/storages/all';
export default {
  inject: ['reload'],
  components: { UploadImage, ShopEditor },
  data() {
    return {
      config,
      setId: '',
      loading: false,
      loading1: false,
      loading2: false,
      baseUrl: process.env.VUE_APP_API_BASE_URL2,
      headers: {
        "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("Access-Token")),
        "Content-Type": 'multipart/form-data'
      },
      withBrand: [],
      withBrandList: [],
      withBrandListAll: [],
      withListAll: [],
      valueCheckbox: [],
      is_all_shop: 2,
      withBrandCheck: [],
      valueInput: '',
      yearPickShow: false,
      isAllCheck: false,
      timeData: [],
      application_timeData: [],
      specList: [{ id: 1, name: '合作项目(收现)' }, { id: 2, name: '产品业绩(收现)' }, { id: 3, name: '基础业绩(收现)' }],
      paymentList: [],
      paymentListYes: [],
      form: {//表单数据
        scheme_identifier: '',
        scheme_name: '',
        // start_date: '',
        // end_date: '',
        share_price: 0.00,
        min_shares: 0,
        premium_rate: 0,
        application_start_date: '',
        application_end_date: '',
        identify_end_date: '',
        dividend_ratio_year: '',
        authorized: 1,
        dividend_settlement_period: 2,
        dividend_order_source: 1,
        dividend_ratio_list: [],
        commission_rule: [],
        commission_rule_no: [],
        commission_rule_yes: [],
        benefit: [],
        institution_ids: '',
      },
      isactive: false,
      isactive1: false,
      isactive2: false,
      step: 1,
      qyStep: 1,
      itemQyType: '',
      itemQyIndex: '',
      modal: {
        visible: false,
        url_loading: false,
        form: {
          name: '',
          image: '',
          count: '1',
          specification: '',
          count_type: 1,
          unit: '',
          operate: '',
          desc: '',
        }
      },
      modal_look: {
        visible: false,
        form: ''
      },
      delList: {
        commission_rule_yes: [],
        commission_rule_no: [],
        dividend_ratio_list: [],
      },
      operate: '',
      imgflag: false,
      inActive: '0level-1',
      valueCheckboxAll: []
    };
  },
  created() {
    console.log('obthis.$route.queryject :>> ', this.$route.query);
    console.log(this.$route, 'this.$route');
    if (this.$route.query.type != 1) {
      this.setId = this.$route.query.id;
      this.getPartnerDetail(this.$route.query.id);
    } else {
      this.getPartnerGenerated()
    }
    if (this.$route.query.type == 1) {
      this.operate = 'add';
      this.modal.form.operate = 'add';
    }
    if (this.$route.query.type == 2) {
      this.operate = 'save';
      this.modal.form.operate = 'save';
    }
    this.getWithBrand();
    this.getPartnerPayList();
  },
  watch: {
    $route() {
      //debugger
      const { path, fullPath, meta, query, params, matched } = this.$route
    },
    valueInput: {
      handler(val) {
        this.isAllCheck = true;
        let list = this.withBrandList;
        if (val) {
          //模糊查询（筛选）
          let arr = list.filter((item) => {
            return item.name.toString().indexOf(val) > -1
          });
          this.withBrandList = arr;
        }
        console.log('withBrandCheck :>> ', this.withBrandCheck);

      },
      deep: true
    },
    valueCheckbox: {
      handler(val) {
        console.log('val :>> ', val);
        let list = [];
        let ids = [];
        this.withBrandListAll.forEach((it, index) => {
          if (val.includes(it.value)) {
            list.push(it)
            ids.push(it.id)
          }
        })
        console.log('list :>> ', list);
        this.withBrandList = list;
        this.withBrandCheck = this.withBrandCheck.concat(ids);
        console.log('withBrandCheck :>> ', this.withBrandCheck);

      },
      deep: true
    }
  },

  methods: {
    moment,
    isCheak(item, id) {
      console.log('item :>> ', item);
      console.log('id :>> ', id);
      let index = item.findIndex(v => v.dividend_business == id);
      if (index != -1) {
        return true
      }
      console.log('index :>> ', index);
    },
    isCheakNo(item, id) {
      let index = item.findIndex(v => v.payment_method == id);
      if (index != -1) {
        return true
      }
    },
    isCheakYes(item, id) {
      let index = item.findIndex(v => v.payment_method == id);
      if (index != -1) {
        return true
      }
    },
    getPartnerPayList() {
      partnerPayTypeList().then(res => {
        let list = res.data;
        //let一个index来接收findIndex的结果，这里arrText为要查找的数组
        let index = list.findIndex((ele) => {
          return ele.id == 7;
        });
        console.log("index", index);
        //当查找失败返回
        if (index === -1) return console.log('删除失败');
        //如果找到该元素，使用splice方法删除元素
        list.splice(index, 1);
        this.paymentList = list;
        this.paymentListYes = list;

      })
    },
    checkAll() {
      this.withBrand.forEach(v => {
        if (!this.valueCheckboxAll.includes(v.value)) {
          this.valueCheckboxAll.push(v.value)
        }
      })
      let ids = [];
      this.withBrandListAll.forEach((it, index) => {
        ids.push(it.id)
      })
      this.withBrandCheck = ids;
    },
    arrayUnrepeat(list1, list2) {
      // 首先构造Object
      const idSet = list1.reduce((acc, v) => {
        acc[v] = true;
        return acc;
      }, {});
      const result = list2.filter(v => !idSet[v]);
      return result
    },
    // 反选
    invertSelection() {
      let idss = [];
      this.withBrandListAll.forEach((it, index) => {
        idss.push(it.id)
      })
      console.log('this.withBrandCheck :>> ', this.withBrandCheck);
      console.log('idss :>> ', idss);
      console.log('this.arrayUnrepeat(idss,this.withBrandCheck) :>> ', this.arrayUnrepeat(idss, this.withBrandCheck));
      this.withBrandCheck = this.arrayUnrepeat(this.withBrandCheck, idss);
      let ids = []
      this.withBrand.forEach(v => {
        if (!this.valueCheckboxAll.includes(v.value)) {
          ids.push(v.value)
        }
      })
      this.valueCheckboxAll = ids
      console.log('this.valueCheckboxAll :>> ', this.valueCheckboxAll);
      this.valueCheckboxAll.forEach(element => {
        let index = this.valueCheckboxAll.findIndex(i => i.value == element)
        console.log('index :>> ', index);
        if (index == -1) return;
        this.valueCheckboxAll.splice(index, 1)
      });


    },
    //重置
    async reset() {
      this.valueCheckboxAll = [];
      this.valueCheckbox = [];
      this.withBrandCheck = [];
      console.log('this.withBrand :>> ', this.withBrand);
      setTimeout(() => {
        this.inActive = '0level-1';
        this.checkboxgroup(this.withBrand[0])
      }, 600);

    },
    checkgroup(e) {
      console.log('e :>> ', e);
      console.log('withBrandCheck :>> ', this.withBrandCheck);
      console.log('withBrandListAll :>> ', this.withBrandListAll);
      let list = []
      let list2 = []
      this.withBrandListAll.forEach(val => {
        if (this.inActive == val.value) {
          list.push(val)
          list2.push(val.id)
        }
      });
      console.log('list :>> ', list);
      if (e) {
        if (this.withBrandCheck.length) {
          // 首先构造Object
          const idSet = this.withBrandCheck.reduce((acc, v) => {
            acc[v] = true;
            return acc;
          }, {});
          const result = list2.filter(v => idSet[v]);
          console.log('result :>> ', result);
          let list3 = []
          result.forEach(val => {
            let str = this.withBrandListAll.find(v => v.id == val);
            if (str) {
              list3.push(str)
            }
          });
          console.log('list3 :>> ', list3);
          this.withBrandList = list3
        } else {
          this.withBrandList = []
        }
      } else {
        this.withBrandList = list
      }
    },
    checkboxgroupCk(e, val) {
      this.inActive = val.value;
      console.log('e :>> ', e.target.checked);
      if (e.target.checked) {
        this.valueCheckboxAll.push(val.value)
        this.valueCheckbox = [val.value]
      } else {
        console.log('val :>> ', val);
        let ids = [];
        this.withBrandListAll.forEach((it, index) => {
          if (val.value.includes(it.value)) {
            ids.push(it.id)
          }
        })
        console.log('ids :>> ', ids);
        console.log('this.withBrandCheck :>> ', this.withBrandCheck);
        ids.forEach(v => {
          let index = this.withBrandCheck.findIndex(i => i == v)
          console.log('index :>> ', index);
          if (index == -1) return;
          this.withBrandCheck.splice(index, 1)
        })
        let index = this.valueCheckboxAll.findIndex(i => i == val.value)
        console.log('index :>> ', index);
        if (index == -1) return;
        this.valueCheckboxAll.splice(index, 1)
      }
    },
    checkboxgroup(e) {
      this.inActive = e.value;
      console.log('e :>> ', e);
      let list = [];
      this.withBrandListAll.forEach((it, index) => {
        if (e.value.includes(it.value)) {
          list.push(it)
        }
      })
      console.log('list :>> ', list);
      this.withBrandList = list;
      // this.valueCheckbox = [e.value]
      // console.log('valueCheckbox :>> ', this.valueCheckbox);
    },
    // 获取门店
    getWithBrand() {
      InstitutionProvince({ isFilterate: 1 }).then(res => {
        this.withBrand = res.data.map((item, index) => {
          item.institution = item.institution.map(it => {
            return { ...it, value: `${index}level-1`, label: it.name }
          })
          return { ...item, value: `${index}level-1`, label: item.province }
        })
        let list = []
        this.withBrand.forEach((v) => {
          list = list.concat(v.institution)
        })
        console.log('list :>> ', list);
        this.withBrandListAll = list;
        this.checkboxgroup(list[0])
        console.log('this.withBrand :>> ', this.withBrand);
      })
    },
    // 获取详情信息
    getPartnerDetail(id) {
      if (!id) return;
      partnerDetail({ id: id }).then(res => {
        console.log(res.data, "data");
        let list_no = [];
        let list_yes = [];
        res.data.commission_rules.forEach(element => {
          if (element.product_type == 1) {
            list_yes.push(element);
          }
          if (element.product_type == 2) {
            list_no.push(element);
          }
        });
        this.form = {
          ...res.data,
          share_price: res.data.subscription_setting.share_price,
          min_shares: res.data.subscription_setting.min_shares,
          premium_rate: (Number(res.data.subscription_setting.premium_rate) * 100).toFixed(2),
          // premium_rate: Number(res.data.subscription_setting.premium_rate),
          benefit: res.data.benefits || [],
          authorized: 1,
          dividend_order_source: 1,
          dividend_ratio_list: res.data.dividend_ratio_list.map(val => {
            val.dividend_rate = (Number(val.dividend_rate) * 100).toFixed(2);
            return val;
          }),
          commission_rule_no: list_no.map(val => {
            val.first_purchase_rate = (Number(val.first_purchase_rate) * 100).toFixed(2);
            val.repurchase_rate = (Number(val.repurchase_rate) * 100).toFixed(2);
            return val;
          }),
          commission_rule_yes: list_yes.map(val => {
            val.first_purchase_rate = (Number(val.first_purchase_rate) * 100).toFixed(2);
            val.repurchase_rate = (Number(val.repurchase_rate) * 100).toFixed(2);
            return val;
          }),
        };
        // this.timeData = [res.data.start_date, res.data.end_date];
        this.application_timeData = [res.data.application_start_date, res.data.application_end_date]
        // console.log('this.timeData :>> ', this.timeData);
        console.log('this.form :>> ', this.form);
      })
    },
    // 获取方案 id
    getPartnerGenerated() {
      partnerGenerated().then((res) => {
        this.form.scheme_identifier = res.data.scheme_identifier;
      })
    },
    // 富文本
    getUser(e) {
      console.log(e);
      this.modal.form.desc = e
    },
    //上传
    handleChange(info) {
      console.log('info :>> ', info);
      if (info.file.status === 'uploading') {
        this.modal.url_loading = true
        return
      }
      if (info.file.status === 'done') {
        const { response = {} } = info.file
        this.modal.form.image = response?.data?.file_url
        this.modal.url_loading = false
      }
    },
    beforeUpload(file, fileList) {
      var self = this
      return new Promise((resolve, reject) => {
        const isLt1M = file.size / 1024 / 1024 > 1
        if (isLt1M) {
          self.$message.error('上传文件大于1MB!')
          reject(false)
        }
        if (fileList.length > 1) {
          self.$message.error('只能上传一张图片！')
          reject(false)
        }
        var fileNames = file.name.split('.')
        var fileType = fileNames[fileNames.length - 1].toLocaleLowerCase()
        var extList = ['jpg', 'png', 'jpeg']
        if (!extList.find((item) => item == fileType)) {
          self.$message.error('只能上传.jpg/.png/.jpeg类型的图片！')
          reject(false)
        }
        resolve(true)
      }).finally(() => {
      })
    },
    // 方案期效
    // onChangeTime(rec) {
    //   console.log('rec :>> ', rec);
    //   this.form.start_date = rec[0] ? rec[0].format('YYYY-MM-DD') : '';
    //   this.form.end_date = rec[1] ? rec[1].format('YYYY-MM-DD') : '';
    //   console.log('rec :>> ', this.form.start_date);
    //   console.log('rec :>> ', this.form.end_date);
    // },
    // 开放时间
    onChangeApplication(rec) {
      console.log('rec :>> ', rec);
      this.form.application_start_date = rec[0] ? rec[0].format('YYYY-MM-DD') : '';
      this.form.application_end_date = rec[1] ? rec[1].format('YYYY-MM-DD') : '';
      this.form.identify_end_date = undefined;
    },
    onChangeIdentify(rec) {
      console.log('rec :>> ', rec);
      this.form.identify_end_date = rec ? rec.format('YYYY-MM-DD') : '';
    },
    handleOpenChange(status) {
      this.isactive2 = !this.isactive2;
      this.yearPickShow = status;
    },
    onChangeYear(rec) {
      console.log('rec :>> ', rec.format('YYYY'));
      this.form.dividend_ratio_year = rec ? rec.format('YYYY') : '';
      this.yearPickShow = false;
    },
    disabledDateTwo(current) {
      console.log('this.form.application_end_date :>> ', this.form.application_end_date);
      return current && current < moment(new Date(this.form.application_end_date));
    },
    disabledDate(current) {
      // Can not select days before today and today// 只能选择今天之后的日期,今天不可选~
      // return current && current < moment().endOf('day');
      // / 不可以选择之前的日期,今天包括之后可以选，控制大于小于来实现选择之前的日期还是之后的日期
      return current < moment().subtract(1, 'day');
    },
    onStep(key) {
      if (this.$route.query.type == 3) {
        this.step = key
      } else {
        if (key == 2) {
          if (!this.form.scheme_name) return this.$message.warning('请填写方案名称！')
          // if (!this.timeData.length) return this.$message.warning('请选择方案效期！')
          if (!this.form.application_start_date) return this.$message.warning('请选择开放时间！')
          if (!this.form.identify_end_date) return this.$message.warning('请选择结束时间！')
          if (!this.form.dividend_ratio_year) return this.$message.warning('请选择统计年度！')
          this.step = key
        }
        if (key == 3) {
          if (this.form.share_price <= 0) return this.$message.warning('请填写正确的消费股价格！')
          if (this.form.min_shares <= 0) return this.$message.warning('请填写正确的起购股数！')
          if (this.form.premium_rate < 0) return this.$message.warning('请填正确的溢价比例！')
          this.step = key
        }
        if (key == 4) {
          if (!this.form.benefit && !this.form.benefit.length) return this.$message.warning('请添加权益！')
          this.step = key
        }
      }

    },
    //取消
    cancel() {
      this.$router.push({ path: 'partnerMenuSetting' })
      this.$emit('onCancel');
    },
    handlePreview(src, type) {
      this.$utils.previewImgVideo({ type: type, response: { data: { file_url: src.image } } })
    },
    addTableItem(key, type) {
      if (this.form[key].some((it) => it.editable)) {
        this.$message.warning('请完成正在编辑的条目操作！')
        return
      }
      this['edit_item'] = undefined
      if (key == 'dividend_ratio_list') {
        this.form[key].push({
          editable: true,
          operate: this.operate,
          dividend_business: undefined,
          dividend_rate: undefined,
          id_index: new Date().getTime(),
        })
      }
      if (key == 'commission_rule_no' || key == 'commission_rule_yes') {
        this.form[key].push({
          editable: true,
          operate: this.operate,
          first_purchase_rate: undefined,
          payment_method: undefined,
          repurchase_rate: undefined,
          product_type: type,
          id_index: new Date().getTime(),
        })
      }
      console.log('this.form[key] :>> ', this.form[key]);
    },
    saveItem(record, index, key) {
      let self = this
      if (key == 'dividend_ratio_list') {
        console.log(record, index, key)
        const { dividend_rate } = record
        const specValueAntDElText = self.$refs['SpecValueRef_' + index].$el.innerText
        console.log(specValueAntDElText)
        let msg
        if (specValueAntDElText.includes('选择类型')) {
          msg = '请选择类型！'
          self.$notification['error']({
            message: '错误',
            description: msg
          })
          return
        }
        if (!dividend_rate) {
          msg = '分红比例不能为空！'
          self.$notification['error']({
            message: '错误',
            description: msg
          })
          return
        }
        setTimeout(() => {
          self.loading = true;
          self.form[key][index].editable = false;
          self.loading = false;
        }, 400)
      }
      if (key == 'commission_rule_no' || key == 'commission_rule_yes') {
        const { first_purchase_rate, repurchase_rate } = record
        const specValueAntDElText = self.$refs['productTypeRef_' + index].$el.innerText
        console.log(specValueAntDElText)
        let msg
        if (specValueAntDElText.includes('选择类型')) {
          msg = '请选择类型！'
          self.$notification['error']({
            message: '错误',
            description: msg
          })
          return
        }
        if (!first_purchase_rate) {
          msg = '首购付款分佣比例不能为空！'
          self.$notification['error']({
            message: '错误',
            description: msg
          })
          return
        }
        if (!repurchase_rate) {
          msg = '再次付款分佣比例不能为空！'
          self.$notification['error']({
            message: '错误',
            description: msg
          })
          return
        }
        setTimeout(() => {
          self.loading1 = true;
          self.loading2 = true;
          self.form[key][index].editable = false;
          self.loading1 = false;
          self.loading2 = false;
        }, 400)
      }

    },
    editItem(item, key) {
      if (this.form[key].some((it) => it.editable)) {
        this.$message.warning('请完成正在编辑的条目操作！')
        return
      }
      console.log('item :>> ', item);
      if (item.id) {
        this.form[key] = this.form[key].map((it) => {
          if (it.id == item.id) {
            this['edit_item'] = it
            it.editable = true
          }
          return it
        })
      } else if (item.id_index) {
        this.form[key] = this.form[key].map((it) => {
          if (it.id_index == item.id_index) {
            this['edit_item'] = it
            it.editable = true
          }
          return it
        })
      }
    },
    cancelItem(index, key) {
      if (!this['edit_item']) {
        this.form[key].splice(index, 1)
        return
      }
      this.form[key] = this.form[key].map((it, ind) => {
        if (index == ind) {
          it.editable = false
        }
        return it
      })
    },
    deleteItem(record, index, key) {
      console.log('record :>> ', record);
      this.form[key].splice(index, 1)
      if (record.id) {
        this.delList[key].push({
          ...record,
          operate: 'del',
        })
      }
    },
    lookItemQy(record, index) {
      this.modal_look.form = record;
      console.log('this.modal_look.form :>> ', this.modal_look.form);
      this.qyStep = 1;
      this.modal_look.visible = true;
    },
    editItemQy(type, record, index) {
      this.itemQyType = type
      if (type == 1) {
        this.modal.form = {
          name: '',
          image: '',
          count: '1',
          specification: '',
          count_type: 1,
          unit: '',
          operate: this.operate,
          desc: '',
        }
      } else {
        this.itemQyIndex = index
        this.modal.form = record;
        this.qyStep = 1;
      }
      this.modal.visible = true;

    },
    deleteItemQy(index) {
      let self = this
      self.$confirm({
        closable: true,
        title: '提示',
        content: '是否删除？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          self.form.benefit.splice(index, 1)
        },
        onCancel() { },
      })
    },
    // 添加权益
    handleOk() {
      if (!this.modal.form.name) return this.$message.warning('请填写权益名称！')
      // if (!this.modal.form.desc) return this.$message.warning('请填写权益详情！')
      this.modal.form.count = this.modal.form.count_type == 1 ? 0 : this.modal.form.count;
      console.log('this.itemQyType :>> ', this.itemQyType);
      console.log('this.modal.form :>> ', this.modal.form);
      if (this.itemQyType == 1) {
        this.form.benefit.push(this.modal.form);
      } else {
        this.form.benefit[this.itemQyIndex] = this.modal.form
      }
      this.modal.visible = false;
      this.qyStep = 1
    },
    handleCancel() {
      this.qyStep = 1;
      this.modal.visible = false
    },
    concatFunction(arr1, arr2) {
      //不要直接使用var arr = arr1，这样arr只是arr1的一个引用，两者的修改会互相影响  
      var arr = arr1.concat();
      //或者使用slice()复制，var arr = arr1.slice(0)  
      for (var i = 0; i < arr2.length; i++) {
        arr.indexOf(arr2[i]) === -1 ? arr.push(arr2[i]) : 0;
      }
      console.log('concatFunction', arr)
      return arr
    },
    submit() {
      if (!this.form.scheme_identifier) {
        this.getPartnerGenerated()
      }
      if (this.form.is_all_shop == 2) {
        if (!this.withBrandCheck.length) return this.$message.warning('请选择指定门店！')
      }
      console.log('this.withBrandCheck :>> ', this.withBrandCheck);
      this.form.institution_ids = this.is_all_shop == 1 ? 'all' : this.withBrandCheck.toString();
      const { commission_rule_no, commission_rule_yes, benefit, dividend_ratio_list } = this.form;
      console.log('commission_rule_no :>> ', commission_rule_no);
      console.log('commission_rule_yes :>> ', commission_rule_yes);
      let arr = this.concatFunction(commission_rule_no, commission_rule_yes)
      let list = benefit.map(v => {
        if (v.id) {
          v.operate = 'save'
        } else {
          v.operate = 'add'
        }
        return v;
      })
      let list2 = dividend_ratio_list.map(v => {
        if (v.id) {
          v.operate = 'save'
        } else {
          v.operate = 'add'
        }
        return v;
      })
      let list3 = arr.map(v => {
        if (v.id) {
          v.operate = 'save'
        } else {
          v.operate = 'add'
        }
        return v;
      })
      console.log('list2 :>> ', list2);
      if (this.delList['dividend_ratio_list'].length) {
        list2 = list2.concat(this.delList['dividend_ratio_list'])
      }
      if (this.delList['commission_rule_no'].length) {
        list3 = list3.concat(this.delList['commission_rule_no'])
      }
      if (this.delList['commission_rule_no'].length) {
        list3 = list3.concat(this.delList['commission_rule_yes'])
      }
      let data = {
        ...this.form,
        commission_rule: list3,
        benefit: list,
        dividend_ratio_list: list2,
      }
      delete data.commission_rule_no;
      delete data.commission_rule_yes;
      const hide = this.$message.loading('正在保存..', 0)
      if (this.$route.query.type == 2) {
        partnerSave(data).then((res) => {
          this.cancel()
        }).finally(r => {
          hide()
        })
      }
      if (this.$route.query.type == 1) {
        partnerAdd(data).then((res) => {
          this.cancel()
        }).finally(r => {
          hide()
        })
      }
    },
    goStep(e) {
      if (this.$route.query.type != 1) {
        this.step = e
      }
    },
    changeRoute() {
      console.log('this.$route.fullpath :>> ', this.$route.fullpath);
      this.$router.push({ path: this.$route.fullpath });
      let newobject = storage.get('seconderyMenuObj')
      Msg.$emit('getpathname', newobject)
    }
  },
};
</script>
<style lang="less" scoped>
.add {
  display: flex;
  font-family: PingFangSC-Medium, PingFang SC;
  padding: 14px 12px 13px;
  flex: 1;
  position: relative;
  .addleft {
    margin-right: 18px;
    width: 56px;
    height: 300px;
    z-index: 10;
    position: fixed;
    .onetxt {
      width: 16px;
      height: 16px;
      background: @stepDbgColor;
      border-radius: 16px;
      text-align: center;
      line-height: 16px;
      margin-bottom: 3px;
      font-size: 12px;
      font-family: Helvetica;
      color: #ffffff;
      cursor: pointer;
      margin-left: 33px;
    }
    .active {
      background: @baseColor11;
    }
    .base {
      cursor: pointer;
      width: 56px;
      height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: @fontColor5;
      line-height: 20px;
      margin-bottom: 4px;
    }
    .active1 {
      color: @baseColor11;
      font-size: 14px;
      font-weight: 400;
    }
    .line {
      width: 1.5px;
      height: 36px;
      background: #ccd7d6;
      margin: 0 5px 6px 40px;
    }
  }
  .addright {
    margin-left: 66px;
    padding-bottom: 20px;
    flex: 1;
    background: #ffffff;
    border-radius: 4px;
    position: relative;
    .zengpinstep {
      padding: 28px 0 26px 35px;
      background: #fff;
      overflow: hidden;
    }
    .twoh1 {
      display: flex;
      margin-bottom: 20px;
      align-items: center;
      .tpointer {
        display: inline-block;
        width: 3px;
        height: 10px;
        background: @primaryColor;
        border-radius: 1px;
        margin-right: 8px;
      }
      .ttxt {
        height: 22px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        line-height: 23px;
        color: @fontColor1;
      }
      .ttxtspan {
        height: 18px;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #a4b3b1;
        line-height: 18px;
        margin-left: 8px;
      }
    }
    .twoh2 {
      display: flex;
      margin-bottom: 22px;
      align-items: center;
      .tpointer {
        display: inline-block;
        width: 3px;
        height: 10px;
        background: @primaryColor;
        border-radius: 1px;
        margin-right: 8px;
      }
      .ttxt {
        height: 22px;
        font-size: 16px;
        font-weight: 600;
        color: @fontColor1;
        line-height: 23px;
      }
    }
    .twoh3 {
      display: flex;
      margin-bottom: 12px;
      align-items: center;
      .tpointer {
        display: inline-block;
        width: 6px;
        height: 6px;
        background: #aed6d8;
        border-radius: 1px;
        margin-right: 8px;
        margin-bottom: 2.5px;
      }
      .ttxt {
        font-size: 14px;
        font-weight: 500;
        color: @fontColor1;
      }
    }
    .one {
      height: calc(100vh - 156px);
    }
    .two {
      height: calc(100vh - 156px);
      overflow-y: auto;
      padding-bottom: 60px;
    }
    .three {
      height: calc(100vh - 150px);
    }
    .four {
      height: 100%;
    }
    .textColor1 {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #131010;
      margin-bottom: 8px;
      .xing {
        color: #f35e67;
        font-weight: 500;
        margin-left: 1px;
      }
    }
    .textSmall {
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: @primaryColor;
      line-height: 26px;
      height: 26px;
      background: @gray-border-background;
      border-radius: 4px;
      opacity: 0.65;
      padding-left: 8px;
      margin-top: 13px;
    }
    .a-center {
      display: flex;
      align-items: center;
      margin-left: 16px;
      .mgl-8 {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        margin-left: 8px;
      }
    }
    .three-btns {
      padding-left: 50px;
      padding-bottom: 20px;
      margin-left: 2px;
      position: fixed;
      bottom: 20px;
      background: #ffffff;
      padding-top: 20px;
      width: 85.37%;
      z-index: 10;
      border-top: 1px solid @dropdownBs5a;
      border-radius: 0 0 4px 4px;
    }
    .textSmalls {
      font-size: 14px;
      font-weight: 400;
      color: @fontColor5;
    }
  }
  /deep/ .ant-table-footer {
    background: @gray-border-background;
  }
}
.shop-title {
  margin: 24px 0 20px 12px;
  height: 22px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: @fontColor1;
  line-height: 22px;
}
.left-title {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(45, 56, 53, 0.8);
  margin-bottom: 30px;
  margin-left: 11px;
}
.right-title {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  margin-bottom: 30px;
  color: @fontColor1;
}
.y-center {
  align-items: center;
  width: 200px;
  justify-content: flex-end;
}
.shop-body {
  background: #fafcfc;
  border-radius: 6px;
  border: 1px solid @borderColorBase;
  margin-left: 12px;
  width: 96%;
  /deep/.ant-input {
    width: 200px;
    border: 1px solid #fafcfc !important;
    background: none !important;
  }
  /deep/.ant-input:hover,
  /deep/.ant-input:focus,
  /deep/.ant-input:focus {
    border: 1px solid @primaryColor !important;
    box-shadow: inset 0px 3px 0px 0px var(--inputinsertHColor, #eaf1f0) !important;
  }
  .top {
    height: 51px;
    line-height: 51px;
    padding-left: 12px;
    border-bottom: 1px solid @borderColorBase;
    .left {
      width: 214px;
    }
    .right {
      width: calc(100% - 214px);
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(45, 56, 53, 0.8);
      display: flex;
      align-items: baseline;
      justify-content: space-between;
    }
  }

  .conter {
    .left {
      // width: 240px;
      height: 525px;
      padding: 6px 12px 12px 6px;
      overflow-y: auto;
      overflow-x: hidden;
      border-right: 1px solid @borderColorBase;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      .item-text {
        padding: 5px 6px;
        margin-top: 10px;
        line-height: 22px;
        display: block;
        width: 201px;
        cursor: pointer;
      }
      .item-text:hover {
        background: @baseColor58;
        color: @primaryColor;
        border-radius: 3px;
      }
      .item-text-corlor {
        background: @baseColor58;
        color: @primaryColor;
        border-radius: 3px;
      }
      /deep/.ant-checkbox-group-item {
        padding: 6px;
        margin-bottom: 6px;
        display: block;
        width: 201px;
      }
      /deep/.ant-checkbox-group-item:hover:hover {
        background: @baseColor58;
        color: @primaryColor;
        border-radius: 3px;
      }
      /deep/.ant-checkbox-wrapper-checked {
        color: @primaryColor;
        background: @baseColor58;
        border-radius: 3px;
      }
    }
    .right3 {
      padding: 6px 12px;
      overflow-y: auto;
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      align-content: flex-start;
      .item-text {
        padding: 6px;
        margin: 3px;
        width: 16.5%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .right {
      /deep/.ant-checkbox-group {
        width: 100%;
      }
      padding: 6px 12px;
      height: 525px;
      width: 86%;
      overflow-y: auto;
      /deep/.ant-checkbox-wrapper {
        margin-bottom: 20px;
        width: 16.5%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      /deep/.ant-checkbox-group-item {
        margin-bottom: 6px;
        width: 201px;
      }
      /deep/.ant-checkbox-group-item:hover:hover {
        color: @primaryColor;
      }
      /deep/.ant-checkbox-wrapper-checked {
        color: @primaryColor;
      }
    }
  }
}
.row-body {
  .top {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    .text-title {
      margin-left: 10px;
      font-size: 14px;
      font-weight: 400;
      color: @btnColorfont1;
    }
    .yuan-blue {
      width: 38px;
      height: 38px;
      text-align: center;
      line-height: 38px;
      color: #ffffff;
      border-radius: 100%;
      background: @inputborderHColor;
      border: 1px solid #0aada1;
    }
    .dian {
      color: #e5e8e5;
      margin: 0 10px;
    }
    .yuan {
      width: 38px;
      height: 38px;
      text-align: center;
      line-height: 38px;
      color: @fontColor7;
      border-radius: 100%;
      background: #fdfdff;
      border: 1px solid #a0c7c4;
    }
  }
}

/deep/ .detailsTable .ant-table .ant-table-thead > tr > th {
  padding: 1px 8px !important;
}
/deep/ .detailsTable .ant-table .ant-table-thead > tr > th:nth-child(1) {
  padding-left: 24px !important;
}
/deep/ .detailsTable .ant-table-row > td:nth-child(1) {
  padding-left: 24px !important;
}
.goods_editor {
  width: 100%;
  height: 307px;
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid #ccc;
  overflow: hidden;
  overflow-y: auto;
}
.font-22 {
  font-size: 22px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: @fontColor1;
}

.wh30Img {
  width: 42px;
  height: 32px;
  box-shadow: 0px 2px 5px 0px rgba(204, 228, 226, 0.35);
  border-radius: 2px;
  border: 1px solid #0bc7b9;
}
.xing {
  font-size: 14px;
  margin-left: 1px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #f35e67;
}
/deep/.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0 !important;
}
.y-tag {
  margin-right: 12px;
  padding: 2px 7px;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}

.plus-icon {
  width: 24px;
  height: 24px;
  padding-top: 3px;
  background: #ffffff;
  box-shadow: 0px 2px 3px 0px rgba(173, 207, 203, 0.35),
    inset 0px -3px 0px 0px #dbeae8;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid #bacbc7;
}
.plus-icon-two {
  font-size: 13.8px !important;
  text-align: center;
  padding-top: 0px !important;
  display: inline-block;
  color: @fontColor1;
  font-weight: 400;
}
.mgt-40 {
  margin-top: 40px;
}
.mgt-20 {
  /deep/.avatar-uploader .ant-upload {
    width: 110px;
    height: 82px;
  }
}
/deep/ .ant-form-item-label,
/deep/ .ant-form-item-control {
  line-height: 30px;
}
/deep/.ant-modal-body .ant-form-item {
  margin-bottom: 30px;
}
</style>